import React from "react"
import { Link, graphql } from "gatsby"
// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark

  const { previous, next } = pageContext

  return (
    <Layout location={location} title="Zurück">
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <img
              src={post.frontmatter.titleicon}
              alt="App Screenshots"
              width="80"
              height="80"
              style={{ margin: 0 }}
            />

            <div style={{ paddingLeft: 20 }}>
              <h1
                style={{
                  margin: 0,
                  fontFamily: "Gothic A1",
                }}
              >
                {post.frontmatter.title}
              </h1>
              {/* <p
                style={{
                  ...scale(-1 / 5),
                  display: `block`,
                  marginBottom: rhythm(1),
                }}
              >
                {post.frontmatter.date}
              </p> */}
            </div>
          </div>
        </header>
        <section
          style={{ fontFamily: "Cutive Mono" }}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        {/* <hr
          style={{
            marginBottom: rhythm(1),
          }}
        /> */}
        {/* <footer>
          <Bio />
        </footer> */}
      </article>

      {/* <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav> */}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        titleicon
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
